.ant-switch-checked {
  background-color: #2c999c !important;
}

.ant-tag-checkable {
  &:hover{
    color: #525252 !important;
  }
  &:active {
    background: #bafaff !important;
  }
  background: #E8F7F8;
  color: #525252;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.ant-tag-checkable-checked {
  background: #2C999C;
  color: #fff;
  &:hover{
    color: #fff !important;
  }
  &:active {
    background: #2C999C !important;
  }
}