.header-adv-content {
    display: flex;
    align-items: center;
    height: 100%;
    .icon-noti{
        font-size: 36px;
        color: #FFFFFF;
        margin-right: 20.25px;
    }
    .noti-adv-text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        color: #FFFFFF;
    }
}