:root {
    --main-color-side-bar: #ffffff;
    --main-color-green: #267d80;
    --main-color-green-light: #2c999c;
    --main-color-slight-green: #dfefef;
    --main-color-black-grey: #65676b;
    --main-color-light-grey: #c2c5cb;
    --main-color-text: #212223;
    --main-color-blue: #4d9fda;
    --main-color-orange: #e78429;
    --main-color-green-backgournd-button: #56babd;
    --main-color-layout-background: #f0f2f5;
    --main-color-boder-fillter-grid: #a3dbdc;

    --main-boder-radius: 8px;
    --main-color-input: #f7f7f7;
}
