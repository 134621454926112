@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .flex-all-items {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.notification-success {
    background-color: #cfffd9 !important;
}
.notification-error {
    background-color: #f58989 !important;
}

.modal-custom {
    .ant-modal-close {
        outline: none;
    }
    .ant-row .ant-form-item {
        margin: 0;
    }
}
.ant-modal-body {
    max-height: 70vh;
    overflow-y: auto;
}
.cell-span {
    background-color: #fff;
    display: flex;
    border: 1px solid #0000001f !important;
}

html,
body,
#__next,
main,
#map {
    font-family: "Rubik", sans-serif;
    height: 100%;
    margin: 0;
    padding: 0;
}

.newStyle .ant-modal-header {
    background-color: #dfefef;
}
.ag-header-cell.text-center {
    .ag-header-cell-label {
        justify-content: center;
    }
}
.newStyle .ant-modal-content {
    border-radius: 8px 8px 8px 8px;
}

.newStyle .ant-modal-content .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
}

.newStyleConfirm .ant-modal-content {
    border-radius: 8px 8px 8px 8px;
}

.newStyleConfirm .ant-modal-content .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
}

.newStyleConfirm .ant-btn-default {
    background-color: #d9d9d9;
}
.newStyleConfirm .ant-btn-primary {
    float: right;
    margin-left: 10px;
    background-color: #d14444;
    border-color: #d14444;
}

.newStyleConfirm .ant-btn:hover,
.ant-btn:focus {
}

.ant-select-selector {
    border-radius: 7px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    // background-color: red;
    // border-color: red;
    border-radius: 6px;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
    height: 15px;
}

.checkbox-red .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #56babd !important;
}

// .header-row-span-2 { position: fixed; top: 50px; height: 100px; }
.ant-spin-dot-item {
    background-color: var(--main-color-green-light) !important;
}

// .ag-cell-wrap-text {
//       word-break: break-word;
// }

// .ag-text-field-input {
//     background-color: var(--main-color-input) !important;
//     ::before {
//         border-width: 1px !important;
//         border-color: var(--main-color-slight-green) !important;
//     }
// }

// .ag-input-wrapper {
//     padding: 6px 8px !important;
// }
