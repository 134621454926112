.body-content {
    background-color: var(--main-color-layout-background);
}
// .ant-menu-title-content{
// 	height: 100%;
// 	width: 10%;
// }
.patient-record-process {
    height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #f0f2f5;
    .content-body-header {
		// margin: 0px 8px 0px 0px;
		text-align: left;
		// height: 216px;
		overflow: auto;
		background-color: #fff;
		border-radius: 42px;
		width: 100%;
		height: 34px;
			.menu-header-custom-process {
				display: flex;
				height: 100%;
				width: 100%;
				.menu-item{
					width:30%;	
					margin-top:0;
					padding-right:0;
					height:100%;
					cursor: default;
					display: flex;
					justify-content: end;
					height: 34px;
					.item-border{
						display: flex;
						justify-content: center;
						align-content: center;
						// cursor: pointer;
						align-items: center;
						border-radius: 42px;
						height: 34px;
						width: 34px;
						.item{
							display: flex;
							justify-content: center;
							align-items: center;
							height: 28px;
							width:28px;
						}
					}
				}
				.menu-item-start{
					width:10%;
					margin-top:0;
					padding-left:0;
					height:100%;
					display: flex;
					justify-content: start;
					.item-border{
						display: flex;
						justify-content: center;
						align-content: center;
						align-items: center;
						background-color: #A3DBDC;
						border-radius: 42px;
						// cursor: pointer;
						height: 34px;
						width: 34px;
						.item-start{
							display: flex;
							justify-content: center;
							align-items: center;
							height: 28px;
							width:28px;
						}
					}
				}
				a {
					color: black;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
				}
			}
	
	
		}
	}
	.content-body-header-decription{
			display: flex;
			text-align: left;
			overflow: auto;
			// background-color: #fff;
			width: 100%;
			height: 34px;
			.item-content-start{
				display: flex;
				align-items: center;
				width: 10%;
			}
			.item-content{
				width:32%;	
				display: flex;
				justify-content: end;
				align-items: center;
			}
			.item-content-end{
				width:26%;	
				display: flex;
				justify-content: end;
				align-items: center;
			}
			.item-text{
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
			}
			.item-text-selected{
				font-family: 'Roboto';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
				color: #2C999C

			}
	}
	.body-upload-file {
		margin: 10px 0px 0px 0px;
		text-align: left;
		// height: 100%;
		flex:1;
		overflow: auto;
		background-color: #fff;
		border-radius: 8px;
	}
   
