@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.body {
	font-family: 'Roboto', sans-serif;

	.header-custom {
		width: calc(100% - 282px);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 6px 8px;
		height: 52px;
		background: #FFFFFF;
		border-radius: 8px;
		flex: none;
		order: 1;
		flex-grow: 1;
		margin: 16px 26px 16px 16px;

		.menu-header-custom {
			display: flex;
			padding-top: 2px;
			width: 100%;
			border-radius: 8px;
			justify-content: space-around;
			.ant-menu-item {
				display: flex;
				flex-direction: row;
				align-items: center;
				color: var(--main-color-black-grey);
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
			}

			.ant-menu-item-selected {
				background-color: var(--main-color-green-light);
				border-radius: 4px;
				a {
					color: #FFFFFF !important;
				}
			}

			a {
				color: var(--main-color-black-grey);
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 16px;
			}
		}
	}
	.content-view {
		background-color: #f0f2f5;
		height: 100%;
		width: 100%;
		.content-body-header {
			margin: 0px 8px 0px 0px;
			text-align: left;
			height: calc(20vh);
			overflow: auto;
			background-color: #fff;
			border-radius: 8px
		}
		.body-patient-care{
			margin: 10px 8px 0px 0px;
			text-align: left;
			height: 72%;
			overflow: auto;
			background-color: #fff;
			border-radius: 8px
		}
	}
	

}