@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.zalo-chat-widget{
    bottom: 5px !important;
}

.ag-header-cell-comp-wrapper{
    height: auto !important;
}

.body {
    font-family: 'Roboto', sans-serif;
    overflow-y: hidden;

    .btn-logout {
        margin-top: 10px;
        width: 100%;
        border-radius: 5px;
        height: 40px;
        background: var(--main-color-green-light);
        color: var(--main-color-side-bar);
        font-size: 15px;
        font-weight: 600;
    }

    .ant-layout-header {
        padding: 0px 24.26px 0px 20px;
        gap: 16px;
        height: 50px;
        background: var(--main-color-green-light);
    }
    .patient-input{
        margin-bottom: 10px;
    }
  
    .top-header-menu {
        height: fit-content;
        display: flex;
        flex-direction: row;
        max-height: 0px;

        .controll-sider{
            margin-top: 8px;
            margin-left: 17px;
            cursor: pointer;
        }

        .header-custom {
            width: calc(100% - 282px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 6px 8px;
            height: 54px;
            background: #FFFFFF;
            border-radius: 8px;
            flex: none;
            order: 1;
            flex-grow: 1;
            margin: 8px 8px 8px 8px;

            .menu-header-custom {
                display: flex;
                padding-top: 2px;
                width: 100%;
                border-radius: 8px;
                justify-content: space-around;

                .ant-menu-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: var(--main-color-black-grey);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;

                }

                .ant-menu-item-selected {
                    background-color: var(--main-color-green-light);
                    border-radius: 4px;

                    a {
                        color: #FFFFFF !important;
                    }
                }

                a {
                    color: var(--main-color-black-grey);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                }
            }


        }
    }


    .content-body {
        margin: 0px 0px 0px 17px;
        text-align: left;
        height: 96%;
        overflow-y: scroll;
        background-color: #fff;
        border-radius: 8px;
    }

    //main height
    .ant-layout-has-sider {
        height: calc(100vh - 68px);
    }

    .site-layout-background {
        min-width: 104px !important;
    }

    .side-bar-menu {
        text-align: center;

        //avt
        .container-user-info {
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .text-info{
                min-height: 67px;
            }

            .line-draw {
                height: 1px;
                background: var(--main-color-light-grey);
                margin-top: 10px;
                width: 80%;
                text-align: center;
            }

            .border-avt-circle {
                border: 3px solid var(--main-color-green-light);
                background-color: #FFFFFF;
                height: 93px;
                border-radius: 50%;
                width: 93px;
                margin-top: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .hello-doctor {
                margin-top: 4px;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: var(--main-color-text);
            }

            .name-doctor {
                margin-top: 2px;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: var(--main-color-green-light);
            }

            .good-luck {
                margin-top: 6px;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                color: var(--main-color-text);
            }
            .change-password {
                margin-top: 6px;
                font-weight: 400;
                font-size: 15px;
                line-height: 15px;
            }
        }

        //menu
        .menu-custom {
            margin: 7px 10px 0px 10px;
            height: calc(100vh - 395px);
            padding: 20px 10px 0px 0px;
            overflow: auto;

            .second-menu {
                margin-top: 26px !important;
            }

            .title-menu {
                display: flex;
                align-items: center;
                margin-left: 14.27px;
                height: 16px;
                color: var(--main-color-green-light);
                margin-bottom: 12px;

                .text-title-menu {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    margin-left: 7.67px;
                }

                .short-text-menu {
                    display: none;
                }

                span {
                    font-size: 22px;
                }
            }

            .ant-menu-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: var(--main-color-green-light);
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                .ant-menu-title-content {
                    width: 100%;
                }

                .short-text-menu {
                    display: none;
                }
            }

            .ant-menu-item-selected {
                background-color: var(--main-color-slight-green);
                border-radius: 4px;
            }

            a {
                color: var(--main-color-green-light);
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
            }
        }


    }

    .ant-form-item-label {
        display: inline-block;
        flex-grow: 0;
        overflow: hidden;
        white-space: nowrap;
        /* text-align: right; */
        vertical-align: middle;
    }
}