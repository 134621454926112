.ag-theme-alpine {
    // --ag-header-column-separator-display: block;
    // --ag-header-column-separator-height: 100%;
    // --ag-header-column-separator-width: 2px;
    // --ag-header-column-separator-color: purple;
    .ag-header-row-column {
        border-top: 1px solid var(--main-color-light-grey) !important;
    }

    .ag-header-cell-resize {
        &::after {
            background-color: var(--main-color-light-grey);
            height: 100%;
            display: block;
            top: 0px;
        }
    }

    .ag-header-cell {
        padding: 12px 8px;
    }

    .ag-root-wrapper {
        border: none;
    }

    .ag-floating-filter {
        border-right: 1px solid var(--main-color-light-grey) !important;
        padding: 0px;
    }

    .ag-floating-filter-button {
        display: none;
    }
    .ag-floating-filter-body {
        height: 100%;
        padding: 0px;
    }
    .ag-input-field {
        height: 100%;
    }
    .ag-text-field-input-wrapper {
        height: 100%;
        padding: 0px;
    }
    .ag-text-field-input {
        height: 100%;
        border: none !important;
    }

    .ag-text-field-input {
    background-color: var(--main-color-input) !important;
    padding: 6px 8px !important;
    ::before {
        border-width: 1px !important;
        border-color: var(--main-color-slight-green) !important;
    }
}

    // .ag-input-wrapper {
    //     padding: 6px 8px !important;
    // }

}

.base-grid {
    .ag-header {
        background-color: var(--main-color-side-bar);
        border-bottom: solid 1px !important;
        // border-bottom-color: var(--main-color-boder-fillter-grid) !important;
    }
    .ag-row {
        border-width: 0px !important;
        border-bottom: 1px solid var(--main-color-light-grey) !important;
    }
    .ag-cell {
        border-right: 1px solid var(--main-color-light-grey) !important;
        border-left: 1px solid var(--main-color-light-grey) !important;
    }
}
