.item-conversation.unread{
    background-color: #F3F5F9;
    border-radius: 8px;
}
.conversation-detail-block{
    position: relative;
    .loading-scroll{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
    }
}
.conversations{
    position: relative;
    .loading-conversation-scroll{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }
}