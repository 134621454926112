@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.ant-input[disabled]{
    color: #000000;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: #000000;
    background: #f5f5f5;
    cursor: not-allowed;
}