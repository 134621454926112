.body-content {
    background-color: var(--main-color-layout-background);
}

.collapse-table {
    .ant-table-row-expand-icon {
        border-radius: 0;
    }

    .ant-table-cell.ant-table-cell-with-append {
    }

    .selected-row {
        background: #b8f0ad !important;
        font-weight: bold;
    }

    .patient-record-grid-session {
        font-weight: bold;
    }
    
    .patient-record-grid-exam {
        font-size: 11pt;
        font-style: italic;
        background: #eeeeee !important;
    }
}

.patient-record {
    display: flex;
    .content-left {
        background-color: #f0f2f5;
        flex: 1;
        border-radius: var(--main-boder-radius);
        margin-right: 10px;
        .content-body-header-left {
            // margin: 0px 8px 0px 0px;
            text-align: left;
            // height: 216px;
            overflow: auto;
            background-color: #fff;
            border-radius: 8px;
        }
        .body-patient-record {
            text-align: left;
            // height: 63%;
            overflow: auto;
            background-color: #fff;
            border-radius: 8px;
        }
    }
    .content-right {
        //width: 20%;
        background-color: #f0f2f5;
        min-width: 360px;
        max-width: 400px;
        border-radius: var(--main-boder-radius);
    }
}
