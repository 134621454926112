.login-page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-family: "Roboto", sans-serif;
    color: #fff;

    img {
        height: 100%;
        width: 100%;
    }

    .box-background {
        position: absolute;
        width: 1170px;
        height: 507px;
        display: flex;
        background: rgba(44, 153, 156, 0.8);
        padding: 43px 59px 43px 59px;

        .box-content-left {
            width: 45%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .content-top {
                .title-top {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36.4px;
                    color: #fff;
                }

                .information {
                    color: #fff;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 25.2px;
                    text-align: center;
                    margin-top: 6px;
                }
            }

            .content-botton {
                display: flex;
                justify-content: space-around;

                .content-botton-left {
                    width: 150px;
                    text-align: center;

                    span {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22.4px;
                        align-items: center;
                    }
                }

                .content-botton-right {
                    width: 150px;
                    text-align: center;

                    span {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22.4px;
                        align-items: center;
                    }
                }

                .number-yesterday {
                    display: flex;
                    margin-top: 16px;
                    align-items: center;

                    .big-number {
                        font-size: 50px;
                        font-weight: 600;
                        margin-right: 24px;
                    }

                    .number-more-type {
                        display: flex;

                        span {
                            margin-right: 5.75px;
                        }

                        i {
                            font-size: 20px;
                        }
                    }

                    span {
                        font-size: 22px;
                        line-height: 25.78px;
                        width: fit-content;
                        font-weight: 500;
                        margin-right: 5.75px;
                    }
                }
            }
        }
    }

    .wrap-form-login {
        z-index: 1;
        width: 1170px;
        display: flex;
        padding-right: 59px;
        border-radius: 8px;
        justify-content: flex-end;
        position: absolute;

        .box-content-right {
            height: 100%;
            width: 50%;
            border-radius: 8px;
            background-color: #fff;
            display: flex;
            align-items: center;
            padding: 51.5px 30px;
            flex-direction: column;

            .title-top {
                width: 440px;
                height: 78px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 26px;
                line-height: 150%;
                text-align: center;
                color: var(--main-color-green);
            }

            .title-login {
                margin-top: 12px;
                margin-bottom: 12px;
                width: 126px;
                height: 26px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 26px;
                text-align: center;
                color: var(--main-color-green-light);
            }

            .body-form-login {
                width: 100%;

                .ant-form-item {
                    margin: 0px !important;
                }

                .ant-form-item-explain-error {
                    font-size: 12px;
                }

                .ant-col-offset-8 {
                    margin-left: 0px;
                }

                .ant-form-item-control-input {
                    min-height: 0px !important;
                }

                .container-input-user {
                    .ant-form-item-control {
                        height: 66px;

                        .ant-input {
                            height: 42px;
                            border: 1px solid var(--main-color-green-light);
                            border-radius: 4px;
                        }
                    }
                }

                .container-input-password {
                    margin-bottom: 2px !important;

                    .ant-form-item-control {
                        height: 66px;

                        .ant-input-password {
                            border: 1px solid var(--main-color-green-light);
                            border-radius: 4px;
                            height: 42px;
                        }
                    }
                }

                .ant-checkbox-inner {
                    border: 1px solid var(--main-color-green-backgournd-button);
                    width: 24px;
                    height: 24px;
                }

                .ant-checkbox-checked {
                    .ant-checkbox-inner {
                        background-color: var(--main-color-green-backgournd-button);
                        border-color: var(--main-color-green-backgournd-button);
                    }

                    :after {
                        height: 13px;
                        width: 8px;
                    }
                }

                .button-login {
                    display: flex;
                    justify-content: center;

                    .ant-form-item-control {
                        display: flex;
                        align-items: center;

                        .ant-btn {
                            margin-top: 38px;
                            width: 100px;
                            height: 32px;
                            border-radius: 4px;
                            background-color: var(--main-color-green-light);
                            border-color: var(--main-color-green-light);
                        }
                    }
                }
            }
        }
    }
}
