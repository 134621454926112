.ant-collapse-item {
  background: none !important;
  border: none !important;
}

.ant-collapse-header {
  position: relative;
  width: 392px !important;
  background: #2C999C;
  color: #fff !important;
  padding: 8px 12px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ant-collapse-expand-icon {
  position: absolute !important;
  right: -10px;
  bottom: 20px;
}

.ant-collapse.ant-collapse-icon-position-end {
  border: none !important;
  background: none !important;
}
.ant-collapse-item.ant-collapse-item-active {
  border: none !important;
}
.ant-collapse-content.ant-collapse-content-active {
  width: 392px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border: none !important;
}
.ant-collapse-content-box {
  padding: 16px 4px 28px !important;
  overflow-y: hidden;
}

.ant-collapse-content-hidden {
  width: 392px !important;
  display: block;
  height: 20px;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.ant-collapse-content-hidden .content {
  display: none;
}
